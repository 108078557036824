import { Link, useParams } from 'react-router-dom';
import './Welcome.scss';

import train_beef from '../../../images/train_beef.png';
import grass from '../../../images/grass.png';

function Welcome() {
  const params = useParams();

  return (
    <div className="container Welcome">
      <div className="background">
        <img className="grass" src={grass} />
      </div>
      <div className="welcome-container">
        <img src={train_beef} />
        <div className="welcome-content">
          <h1 className="header-text">Your expert-led game plan for a better relationship with your pup.</h1>
          <p>Sign up for FREE access to our <span>30 day Perfect Pup Training Course</span> and save 10% on Pupford Products*</p>
          <Link
            to={`/${params.partner}/get-started`}
          >
            <button className="primaryBtn">Get Access & Save</button>
          </Link>
          <p>*Offer exclusive to Pupford products purchased in your local retail store</p>
        </div>
      </div>
    </div>
  )
}

export default Welcome;
