import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import pupfordFullLogo from '../../../images/pupford_logo_2x.png';
import menu from '../../../images/menu.png';
import close from '../../../images/close.png';
import qrCream from '../../../images/qr_code_cream.png';
import qrBrown from '../../../images/qr_code_brown.png';
import plusIcon from '../../../images/plus_icon.png';

import './Header.scss';

function Header({courses, showBanner, partnerLogo}) {
  let location = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [couponAvailable, setCouponAvailable] = useState(true);
  const [couponModalOpen, setCouponModalOpen] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('/welcome') || location.pathname.includes('/get-started') || location.pathname === '/') {
      setShowMenu(false);
    } else if (!showMenu) {
      setShowMenu(true);
    }
  }, [location]);

  return (
    <>
      <header className="Header drop-shadow">
        {/* {!showBanner ? null:
          <div
            className="coupon-banner"
            onClick={() => {
              setCouponModalOpen(true);
            }}
          >
            <img src={qrCream} height="16px" />
            <p>Don't forget your coupon at checkout</p>
          </div>
        } */}
        <div className="content-max-width">
          {/* <div className="header-content" style={showBanner ? {marginTop: '50px'} : null}> */}
          <div className="header-content">
            {!showMenu ? null :
              <div className="header-menu-control">
                {menuOpen ?
                  <img
                    src={close}
                    onClick={() => setMenuOpen(!menuOpen)}
                    height="24" 
                  /> :
                  <img
                    src={menu}
                    onClick={() => setMenuOpen(!menuOpen)}  
                  />
                }
              </div>
            }
            <img
              src={pupfordFullLogo}
              height="28"
            />
            {!partnerLogo ? null :
              <>
                <img src={plusIcon} height="10"/>
                <img src={partnerLogo} height="40" />
              </>
            }
          </div>
        </div>
        
        {courses.length && menuOpen ?
          <div className="header-menu">
            {courses[0].modules.map((m, i) => {
              return (
                <Link
                  className="menu-module-link"
                  key={`course-module-${i}`}
                  to={`/${location.pathname.split('/')[1]}/${courses[0].pathName}-${i}`}
                  onClick={() => setMenuOpen(false)}
                >
                  <p className="module-title">{m.title}</p>
                  <p>{m.description}</p>
                  <hr className="module-divider" />
                </Link>
              );
            })}
          </div> :
          null        
        }
      </header>

      {!couponModalOpen ? null :
        <div
          className="modal"
        >
          <div className="modal-body">
            <div className="close-button" onClick={() => setCouponModalOpen(false)}>
              <img src={close}/>
            </div>

            <div className="modal-content">
              <p>Thanks! Scan this at checkout for 10% off your Pupford purchase.</p>
              <hr className="solid"/>
              <div className="coupon-container">
                <img src={qrBrown} height="174px" />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Header;
