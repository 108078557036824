import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { config } from '../config';
import Header from './layout/Header';
import Courses from './Courses';

function Main({}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [showHeaderBanner, setShowHeaderBanner] = useState(false);
  const [partnerLogo, setPartnerLogo] = useState('');
  const [partnerModuleProducts, setPartnerModuleProducts] = useState({});

  useEffect(() => {
    fetchPartnerData();
  }, []);

  const fetchPartnerData = async () => {
    try {
      const partnerSlug = location.pathname.split('/')[1];

      if (!partnerSlug) {
        return;
      }

      const result = await axios.post(`${config.functionsCDN}/getFreeTrainingPartnerData`, {
        data: {
          freeTrainingUrl: partnerSlug,
        },
      });

      if (!result || !result.data || !result.data.result || !result.data.result.logoUrl) {
        navigate('/');
      } else {
        setPartnerLogo(result.data.result.logoUrl);
        setPartnerModuleProducts(result.data.result.courseProducts || {});
      }
    } catch (e) {
      console.log('error', e);
      navigate('/');
    }
  };

  return (
    <>
      <Header
        courses={courses}
        showBanner={showHeaderBanner}
        partnerLogo={partnerLogo}
      />

      {/* <div className="main-container-min-height"> */}
      <div>
        <Courses
          coursesAvailable={(courseModules) => {
            setCourses(courseModules);
          }}
          showHeaderBanner={(showBanner) => {
            setShowHeaderBanner(showBanner);
          }}
          partnerModuleProducts={partnerModuleProducts}
        />
      </div>
    </>
  );
}

export default Main;
