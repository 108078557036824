import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

import './Courses.scss';
import Home from './Home';
import CourseHome from './CourseHome';
import CourseModule from './CourseModule';
import Loading from '../shared/Loading';
import Welcome from './Welcome';
import GetStarted from './GetStarted';
import NoMatch from './NoMatch';

function Courses({coursesAvailable, showHeaderBanner, partnerModuleProducts}) {
  const [loading, setLoading] = useState(true);
  const [shouldFetchCourses, setShouldFetchCourses] = useState(false);
  const [courses, setCourses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setShouldFetchCourses(true);
  }, []);

  useEffect(() => {
    if (shouldFetchCourses) {
      fetchCourses();
    }
  }, [shouldFetchCourses]);

  const fetchCourses = async () => {
    const db = getFirestore();

    try {
      const settingsConfigRef = doc(db, 'config', 'retailer-microsite');
      const settingsConfigSnap = await getDoc(settingsConfigRef);
      const settingsConfigData = settingsConfigSnap.data();
      const courseIds = settingsConfigData.includedCourses.map(c => c.id);
      const courses = [];
      const categoriesMap = {};

      for (let i = 0; i < courseIds.length; i++) {
        const id = courseIds[i];
        const freeCourseRef = doc(db, 'free-courses', id);
        const freeCourseSnap = await getDoc(freeCourseRef);
        const freeCourse = freeCourseSnap.data();

        if (freeCourse) {
          courses.push({
            ...freeCourse,
            id: freeCourseSnap.id,
            pathName: freeCourse.name.replace(/\/| /g, '-').toLowerCase(),
          });
        } else {
          const paidCourseRef = doc(db, 'membership-content', id);
          const paidCourseSnap = await getDoc(paidCourseRef);
          const paidCourse = paidCourseSnap.data();

          if (paidCourse) {
            courses.push({
              ...paidCourse,
              id: paidCourseSnap.id,
              pathName: paidCourse.name.replace(/\/| /g, '-').toLowerCase(),
            });
          }
        }
      }

      const mainPageCourses = courses.map(c => {
        const course = { ...c };

        if (course.modules) {
          course.modules = course.modules.filter(m => typeof m !== 'string');
        } else {
          course.modules = [];
        }

        return course;
      });

      mainPageCourses.forEach(course => {
        if (course.modules) {
          course.modules.forEach((m, moduleIndex) => {
            if (m.categories) {
              m.categories.forEach(c => {
                if (c && c !== 'None') {
                  const module = {
                    ...m,
                    moduleIndex,
                    pathName: course.name.replace(/\/| /g, '-').toLowerCase(),
                  };

                  if (categoriesMap[c]) {
                    categoriesMap[c].push(module);
                  } else {
                    categoriesMap[c] = [ module ];
                  }
                }
              });
            }
          });
        }
      });

      const categories = [];

      for (let key in categoriesMap) {
        categories.push({
          name: key,
          modules: categoriesMap[key],
        });
      }

      setCourses(mainPageCourses);
      coursesAvailable(mainPageCourses);
      setCategories(categories.sort((a, b) => a.name.localeCompare(b.name)));
      setErrorMessage('');
    } catch (e) {
      console.log('error:', e);
      setErrorMessage('There was an error loading the courses, please try again. If this problem continues, please contact us.');
    }

    setLoading(false);
  };

  return (
    <div className="Courses">
      {!errorMessage ? null :
        <div className="error-message-container">
          <h1>Oops! There Was an Error</h1>
          <p>{errorMessage}</p>
        </div>
      }
      {loading ? <Loading /> :
        <Routes>
          <Route
            path=":partner"
          >
            <Route
              path=":course"
              element={
                <CourseModule
                  courses={courses}
                  partnerModuleProducts={partnerModuleProducts}
                />
              }
            />
            <Route
              path=""
              element={
                <CourseHome
                  courses={courses}
                  categories={categories}
                  showHeaderBanner={showHeaderBanner}
                />
              }
            />
            <Route
              path="welcome"
              element={
                <Welcome showHeaderBanner={showHeaderBanner}/>
              }
            />
            <Route
              path="get-started"
              element={
                <GetStarted />
              }
            />
            <Route
              path="old"
              element={
                <Home
                  courses={courses}
                  categories={categories}
                />
              }
            />
          </Route>
          <Route path="*" element={<NoMatch/>} />
        </Routes>
      }
    </div>
  );
}

export default Courses;
