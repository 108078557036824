import { Link, useParams } from 'react-router-dom';

import "./GetStarted.scss";
import train_beef from '../../../images/train_beef.png';

function GetStarted() {
  const params = useParams();

  return (
    <div className="container GetStarted">
      <img className="train-img" src={train_beef} />
      <div className="content-container flex-column">  
        <h1 className="content-title">Tell us a little about yourself and your pup.</h1>
        <input className="primary-input" placeholder="Email address"></input>
        <input className="primary-input" placeholder="Pup name (optional)"></input>
        <a href="https://pupford.com/privacy-policy/" className="text-link" style={{alignSelf: 'flex-start', marginBottom: '24px'}}>Privacy Policy</a>
        <Link
          to={`/${params.partner}`}
          style={{width: '100%'}}
        >
          <button className="primaryBtn">Get Started</button>
        </Link>
      </div>
    </div>
  )
}

export default GetStarted;