import React from 'react';

import './loading.scss';
import pupfordLogo from '../../../images/logo-p.png';

function Loading({ position }) {
  return (
    <div className="Loading">
      <div className="main-loading-container" style={{ position: position || 'fixed' }}>
        <div className="main-loading-container-inner">
          <div className="main-loading-logo-container">
            <img className="main-loading-logo" src={pupfordLogo} alt="Pupford" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
