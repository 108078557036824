import { Link } from 'react-router-dom';
import engageYakcheese from '../../../images/engage_yakcheese.png';

import './Home.scss';

function Home({ courses, categories }) {
  return (
    <div className="Home">
      <div className="content-max-width">
        {!courses[0] ? null :
          <>
            <div className="featured-course-container">
              <img src={engageYakcheese} />
              <div className="featured-course-banner">
                <h3>{courses[0].name}</h3>
                <p>{courses[0].description}</p>
              </div>
            </div>

            <div className="content-container" id="main-course-modules">
              <h2>Modules</h2>

              <div className="link-bar modules-container">
                {courses[0].modules.map((m, i) => {
                  return (
                    <Link
                      key={`featured-course-module-${i}`}
                      className="link-tile"
                      to={`/${courses[0].pathName}-${i}`}
                    >
                      <div className="module-image-wrapper">
                        <img
                          src={m.backgroundImage}
                        />
                      </div>

                      <h3>{m.title}</h3>

                      <p>{m.description}</p>
                    </Link>
                  );
                })}
              </div>

              <h2 className="alternate-heading">Additional Courses</h2>

              {courses.length < 2 ? null :
                <>
                  {courses.map((c, i) => {
                    if (i === 0) {
                      return;
                    }

                    return (
                      <div key={`course-${i}`}>
                        <h2>{c.name}</h2>

                        <div className="link-bar modules-container">
                          {c.modules.map((m, i) => {
                            return (
                              <Link
                                key={`featured-course-module-${i}`}
                                className="link-tile"
                                to={`/${c.pathName}-${i}`}
                              >
                                <div className="module-image-wrapper">
                                  <img
                                    src={m.backgroundImage}
                                  />
                                </div>

                                <h3>{m.title}</h3>

                                <p>{m.description}</p>
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </>
              }

              {!categories.length ? null :
                <>
                  <h2 className="alternate-heading">Training Categories</h2>

                  {categories.map((c, i) => {
                    return (
                      <div key={`course-${i}`} id={c.name.replace(/\/| /g, '-').toLowerCase()}>
                        <h2>{c.name}</h2>

                        <div className="link-bar modules-container">
                          {c.modules.map((m, i) => {
                            return (
                              <Link
                                key={`featured-course-module-${i}`}
                                className="link-tile"
                                to={`/${m.pathName}-${m.moduleIndex}`}
                              >
                                <div className="module-image-wrapper">
                                  <img
                                    src={m.backgroundImage}
                                  />
                                </div>

                                <h3>{m.title}</h3>

                                <p>{m.description}</p>
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </>
              }
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default Home;
