import { useEffect, useState } from 'react';

import { Link, useParams } from 'react-router-dom';
import engageYakcheese from '../../../images/engage_yakcheese.png';

import './CourseHome.scss';

function CourseHome({courses, categories, showHeaderBanner}) {
  const params = useParams();
  const [showAllModules, setShowAllModules] = useState(false);
  const [modulesToShow, setModulesToShow] = useState([]);
  const [showEarthwiseBanner, setShowEarthwiseBanner] = useState(false);

  useEffect(() => {
    showHeaderBanner(true);
  }, []);

  useEffect(() => {
    if (params.partner === 'earthwise-pet') {
      setShowEarthwiseBanner(true);
    }
  }, [params.partner]);
  
  useEffect(() => {
    if (showAllModules) {
      setModulesToShow(courses[0].modules);
    } else {
      const firstThreeModules = courses[0].modules.slice(0, 3);
      setModulesToShow(firstThreeModules);
    }
  }, [showAllModules]);

  return (
    <div className="container homeContainer">
      <div>
        <img className="imageContainer" src={engageYakcheese} />
      </div>
      <div className="homeBanner">
        {showEarthwiseBanner ?
          <>
            <h1>Welcome to 30 Day Perfect Pup</h1>
            <p className="bannerSubTitle">Full-course membership COMING SOON to EarthWise Pet and Pet Pros locations. In the meantime, enjoy some free content!</p>
          </> :
          <>
            <h1>Welcome to 30 Day Perfect Pup</h1>
            <p className="bannerSubTitle">A well-behaved dog in less than a month.</p>
          </>
        }
      </div>
      <hr className="solid"/>
      <div className="courseModulesContainer">
        {modulesToShow.map((m, i) => {
          return (
            <Link
              key={`featured-course-module-${i}`}
              className="moduleLink boxShadow"
              to={`/${params.partner}/${courses[0].pathName}-${i}`}
            >
              <div className="moduleImageWrapper containImage">
                <img
                  src={m.backgroundImage}
                />
              </div>
              <div className="moduleLinkText">
                <h3>{m.title}</h3>
                <p>{m.description}</p>
              </div>
            </Link>
          );
        })}
        {showAllModules ? null :
          <button className="secondaryBtn" onClick={() => setShowAllModules(true)}>
            See all 30 days
          </button>
        }
      </div>
    </div>
  )
}

export default CourseHome;
