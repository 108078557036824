import './NoMatch.scss';

function NoMatch() {
  return (
    <div className="NoMatch">
      <h1>Oops, it looks like this page doesn't exist.</h1>
      <p>This page may have been moved. Try navigating back a page or please contact us if you have any troubles finding what you're looking for.</p>
    </div>
  );
}

export default NoMatch;
